import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles, withStyles} from '@mui/styles';
import {
  addFollowUpSurveyEntry, addSurveyEntry,
  addTargetValue, deleteEntryFromFollowUpSurvey, deleteEntryFromSurvey,
  deleteTargetValue, getActivity, searchLocations,
  updateActivity
} from "../../utils/api";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import {formatTime} from '../../utils/timeHelper';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import EditableInput from "../input/EditableInput";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {Autocomplete, Checkbox, CircularProgress, FormGroup} from "@mui/material";
import {
  FormControl, FormControlLabel,
  IconButton,
  ImageList, Radio, RadioGroup,
  TableContainer, Tooltip
} from "@mui/material";
import {
  getInTextActivityType
} from "../../utils/activityTypeHelper";
import DeleteIcon from "@mui/icons-material/Delete";
import AddTargetValueDialog from "../dialogs/AddTargetValueDialog";
import {useParams} from "react-router-dom";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  contentWrapper: {
    margin: 'auto'
  },
  tableContainer: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
    overflow: 'hidden',
  },
  tableHead: {
    background: '#F5F5F5',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function Activity() {
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [distributionValue, setDistributionValue] = React.useState(1);
  const [improvementValue, setImprovementValue] = React.useState(1);
  const [stateActivity, setStateActivity] = useState(null);
  const [activityBuffer, setActivityBuffer] = useState(null);

  let {activityId} = useParams();

  const authState = useContext(UserContext);

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getActivity(activityId);
          const activityData = result.data;

          if (!cancelRequest.current) {
            if (activityData) {
              setIsLoading(false);
              setStateActivity(activityData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  const outputs = [
    { name: 'Output 1', value: 'OUTPUT_1', description: '- Les capacités des acteurs en vue de mettre en œuvre des mesures visant à promouvoir '
          + 'la durabilité environnementale, économique et sociale dans et autour '
          + 'du secteur minier sont améliorées.' },
    { name: 'Output 2', value: 'OUTPUT_2', description: '- Les provinces du Haut-Katanga et du Lualaba ont progressé dans la création de sources'
          + ' de revenus respectueuses de l’environnement et des droits humains pour'
          + ' les mineur·euse·s artisa-naux·ales.' },
    { name: 'Output 3', value: 'OUTPUT_3', description: '- Les apprenti·e·s et les personnes intéressées par le travail indépendant des communautés'
          + ' minières du Haut-Katanga et du Lualaba utilisent des offres de qualification'
          + ' améliorées et axées sur la pratique.' },
    { name: 'Output 4', value: 'OUTPUT_4', description: '- Les capacités d’un recouvrement des impôts plus efficace des entreprises minières'
          + ' ont été améliorées au sein de l’administration fiscale congolaise.' },
  ];

  const setReviewedConfirmationHandler = async () => {
    await updateActivityDataAndDispatch('reviewed', true);
    await fetchData(true);
  };

  const restoreConfirmationHandler = async () => {
    await updateActivityDataAndDispatch('deleted', false);
    await fetchData(true);
  };

  const handleImprovementRadioChange = (event) => {
    updateActivityDataAndDispatch('improvement', event.target.value);
    setImprovementValue(event.target.value);
  };

  const handleDistributionRadioChange = (event) => {
    updateActivityDataAndDispatch('distribution', event.target.value);
    setDistributionValue(event.target.value);
  };

  const handleOutputValueChange = (event, values) => {
    updateActivityDataAndDispatch('outputs', values);
  };

  const handleCompletionStatusChange = (status) => {
    updateActivityDataAndDispatch('completed', status);
  };

  const addTargetValueConfirmationHandler =
      async (numericValue,
          type) => {
        await addTargetValue(stateActivity.id, {
          'numericValue': +numericValue,
          'type': type !== '' ? type : null,
        });
        await fetchData(true);
      };

  const addSurveyEntryConfirmationHandler =
      async (gender, age, newCompetencies,
          newCompetenciesPractical, newCompetenciesColleagues,
          activityRating, comment) => {
        await addSurveyEntry(stateActivity.id, {
          'gender': gender !== '' ? gender : null,
          'age': +age > 0 ? +age : null,
          'newCompetencies': newCompetencies !== '' ? newCompetencies : null,
          'newCompetenciesPractical': newCompetenciesPractical !== '' ? newCompetenciesPractical : null,
          'newCompetenciesColleagues': newCompetenciesColleagues !== '' ? newCompetenciesColleagues : null,
          'activityRating': activityRating !== '' ? activityRating : null,
          'comment': comment !== '' ? comment : null,
        });
        await fetchData(true);
      };

  const addFollowUpSurveyEntryConfirmationHandler =
      async (gender, age, newCompetenciesPractical, newCompetenciesColleagues,
          additionalQuestion, additionalQuestionAnswer, comment) => {
        await addFollowUpSurveyEntry(stateActivity.id, {
          'gender': gender !== '' ? gender : null,
          'age': +age > 0 ? +age : null,
          'newCompetenciesPractical': newCompetenciesPractical !== '' ? newCompetenciesPractical : null,
          'newCompetenciesColleagues': newCompetenciesColleagues !== '' ? newCompetenciesColleagues : null,
          'additionalQuestion': additionalQuestion !== '' ? additionalQuestion : null,
          'additionalQuestionAnswer': additionalQuestionAnswer !== '' ? additionalQuestionAnswer === 'YES' : null,
          'comment': comment !== '' ? comment : null,
        });
        await fetchData(true);
      };

  const updateActivityDataAndDispatch = async (field, value) => {
    try {
      setActivityBuffer({
        ...activityBuffer,
        [field]: value,
      });
      const { data: activityData } = await updateActivity(
          stateActivity.id,
          { [field]: value }
      );

      if (!cancelRequest.current) {
        setStateActivity(activityData);
      }
    } catch (error) {
      setActivityBuffer(activityBuffer);
    }
  };

  const removeTargetValue = async (activityId, targetValueId) => {
    await deleteTargetValue(activityId, targetValueId);
    await fetchData(true);
  };

  const removeEntryFromSurvey = async (activityId, entryId) => {
    await deleteEntryFromSurvey(activityId, entryId);
    await fetchData(true);
  };

  const removeEntryFromFollowUpSurvey = async (activityId, entryId) => {
    await deleteEntryFromFollowUpSurvey(activityId, entryId);
    await fetchData(true);
  };

  const handleActivityTypeChange = async (event) => {
    await updateActivityDataAndDispatch('activityType', event.target.value);
    await fetchData(true);
  };

  const getLockQuestion = () => {
    var i;
    var lockQuestion = '';

    if (stateActivity.followUpSurvey && stateActivity.followUpSurvey.surveyEntries) {
      const surveyEntries = stateActivity.followUpSurvey.surveyEntries;

      for (i = 0; i < surveyEntries.length; i++) {
        if (surveyEntries[i].additionalQuestion &&
            surveyEntries[i].additionalQuestion !== '') {
          lockQuestion = surveyEntries[i].additionalQuestion;
          break;
        }
      }
    }

    return lockQuestion;
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              Accès refusé !
            </div>
          </>
      );
    }

    if (isLoading || !stateActivity) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary"/>
            </div>
          </>
      );
    }

    return <React.Fragment>
            <Paper elevation={0} variant="outlined" className={classes.paper}>
              <AppBar className={classes.toolbar}
                      position="static"
                      color="default"
                      elevation={0}>
                <Toolbar>
                  <Typography variant="h6" gutterBottom component="div">
                    1. Informations générales
                  </Typography>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                <ImageList cellHeight={'auto'} className={classes.gridList}
                           cols={2}>
                  <DescriptionList>
                    <DescriptionTerm>Type</DescriptionTerm>
                    <Description>
                      <Select
                          labelId="activity-type-select"
                          id="activity-type-select"
                          value={stateActivity.activityType}
                          className={classes.selectEmpty}
                          onChange={handleActivityTypeChange}
                      >
                        <MenuItem value={'SEMINAR'}>Séminaire /
                          Formation</MenuItem>
                        <MenuItem value={'WORKSHOP'}>Atelier / Workshop</MenuItem>
                        <MenuItem value={'PUBLICATION'}>Publication</MenuItem>
                        <MenuItem value={'CAMPAIGN'}>Campagne médiatique</MenuItem>
                        <MenuItem value={'OTHER'}>Autre</MenuItem>
                      </Select>
                    </Description>
                  </DescriptionList>
                  {stateActivity.activityType === 'OTHER' &&
                    <DescriptionList>
                      <DescriptionTerm>Autre type</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="otherTypeName"
                            value={stateActivity.otherTypeName}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName,
                                value) => updateActivityDataAndDispatch(fieldName,
                                value)}
                        />
                      </Description>
                    </DescriptionList>
                  }
                </ImageList>
                <ImageList cellHeight={'auto'} className={classes.gridList}
                           cols={2}>
                  <DescriptionList>
                    <DescriptionTerm>Nom</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="name"
                          value={stateActivity.name}
                          disabled={false}
                          fullWidth={true}
                          size={'large'}
                          multiline={true}
                          rows={4}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Lieu</DescriptionTerm>
                    <Description>
                      <BasicAutocomplete
                          handleChange={(value) => updateActivityDataAndDispatch(
                              'location', value)} searchFct={searchLocations}
                          currentValue={stateActivity.location}
                          property={'englishName'}/>
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Dates de début</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="date"
                          type="date"
                          value={formatTime(stateActivity.date, 'dd.LL.yyyy')}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value + ' 00:00:00.000')}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Dates de fin</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="endDate"
                          type="date"
                          value={formatTime(stateActivity.endDate,
                              'dd.LL.yyyy')}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value + ' 00:00:00.000')}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Objectif</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="objective"
                          value={stateActivity.objective}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Commentaires</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="comment"
                          value={stateActivity.comment}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <FormGroup>
                      <FormControlLabel
                          sx={{ alignItems: 'flex-start', marginTop: 2 }}
                          control={
                            <Checkbox
                                checked={stateActivity.completed}
                                color="primary"
                                sx={{
                                  marginTop: -1.6,
                                }}
                                onChange={(e) => {
                                  let checked = e.target.checked;
                                  handleCompletionStatusChange(checked);
                                }
                                } />}
                          label="Activité réalisée" />
                    </FormGroup>
                  </DescriptionList>
                </ImageList>
              </div>
            </Paper>
            <Paper elevation={0} variant="outlined" className={classes.paper}>
              <AppBar className={classes.toolbar}
                      position="static"
                      color="default"
                      elevation={0}>
                <Toolbar>
                  <Typography variant="h6" gutterBottom component="div">
                    2. Cibles prévues
                  </Typography>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                <TableContainer style={{width: '95%'}}>
                  <Table size="small">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell width={'10%'}>Nombre</TableCell>
                        <TableCell width={'30%'}>Type</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stateActivity.targetValues.map((targetValue) => (
                          <TableRow key={targetValue.id}>
                            <TableCell
                                width={'10%'}>{targetValue.numericValue}</TableCell>
                            <TableCell
                                width={'30%'}>{targetValue.type}</TableCell>
                            <TableCell>
                              <Tooltip title="Supprimer">
                                <IconButton onClick={() => removeTargetValue(
                                    stateActivity.id, targetValue.id)}>
                                  <DeleteIcon className={classes.block}
                                              color="inherit"/>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.contentWrapper}>
                  {authState.role !== 'ROLE_READER' &&
                  <AddTargetValueDialog
                      confirmationHandler={addTargetValueConfirmationHandler}/>
                  }
                </div>
              </div>
            </Paper>
            <Paper elevation={0} variant="outlined" className={classes.paper}>
              <AppBar className={classes.toolbar}
                      position="static"
                      color="default"
                      elevation={0}>
                <Toolbar>
                  {stateActivity.activityType !== 'PUBLICATION'
                  && stateActivity.activityType !== 'CAMPAIGN' &&
                  <Typography variant="h6" gutterBottom component="div">
                    3. Informations sur les participants
                  </Typography>
                  }
                  {stateActivity.activityType === 'PUBLICATION' &&
                  <Typography variant="h6" gutterBottom component="div">
                    3. Informations sur la publication
                  </Typography>
                  }
                  {stateActivity.activityType === 'CAMPAIGN' &&
                  <Typography variant="h6" gutterBottom component="div">
                    3. Informations sur la campagne
                  </Typography>
                  }
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                {stateActivity.activityType !== 'PUBLICATION'
                && stateActivity.activityType !== 'CAMPAIGN' &&
                <ImageList cellHeight={'auto'} className={classes.gridList}
                           cols={3}>
                  <DescriptionList>
                    <DescriptionTerm>Nombre total de
                      participants</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="numberParticipants"
                          value={stateActivity.numberParticipants}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Dont nombre de femmes</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="numberFemaleParticipants"
                          value={stateActivity.numberFemaleParticipants}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Dont nombre de jeunes (estimation: 15-30 ans)</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="numberYoungParticipants"
                          value={stateActivity.numberYoungParticipants}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Facilitateur</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="moderator"
                          value={stateActivity.moderator}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Organisateur</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="organizer"
                          value={stateActivity.organizer}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                </ImageList>
                }
                {stateActivity.activityType === 'PUBLICATION' &&
                <ImageList cellHeight={'auto'} className={classes.gridList}
                           cols={3}>
                  <DescriptionList>
                    <DescriptionTerm>Auteur</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="author"
                          value={stateActivity.author}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Publié par</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="publisher"
                          value={stateActivity.publisher}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Nombre d'impressions</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="numberOfViews"
                          value={stateActivity.numberOfViews}
                          type="number"
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                </ImageList>
                }
                {stateActivity.activityType === 'CAMPAIGN' &&
                <ImageList cellHeight={'auto'} className={classes.gridList}
                           cols={3}>
                  <DescriptionList>
                    <DescriptionTerm>Mise en œuvre par</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="implementor"
                          value={stateActivity.implementor}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(
                              fieldName, value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Diffusée par</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="distributor"
                          value={stateActivity.distributor}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                </ImageList>
                }
              </div>
            </Paper>
            {stateActivity.activityType !== 'CAMPAIGN' &&
            <Paper elevation={0} variant="outlined" className={classes.paper}>
              <AppBar className={classes.toolbar}
                      position="static"
                      color="default"
                      elevation={0}>
                <Toolbar>
                  <Typography variant="h6" gutterBottom component="div">
                    4. Évaluation
                  </Typography>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                {stateActivity.activityType !== 'CAMPAIGN' &&
                <>
                  <Typography variant="p" gutterBottom component="div">
                    Portée de l'activité
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup row={true} aria-label="distribution"
                                name="distribution"
                                value={stateActivity.distribution}
                                onChange={handleDistributionRadioChange}>
                      <FormControlLabel value="STRONG"
                                        control={<Radio color="primary"/>}
                                        label="forte"/>
                      <FormControlLabel value="MEDIUM"
                                        control={<Radio color="primary"/>}
                                        label="moyennne"/>
                      <FormControlLabel value="WEAK"
                                        control={<Radio color="primary"/>}
                                        label="faible"/>
                    </RadioGroup>
                  </FormControl>
                </>
                }
                {stateActivity.activityType !== 'CAMPAIGN'
                && stateActivity.activityType !== 'PUBLICATION' &&
                <>
                  <Typography variant="p" gutterBottom component="div">
                    Votre {getInTextActivityType(
                      stateActivity.activityType)} a-t-il présenté des résultats
                    imprévus ?
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup row={true} aria-label="improvement"
                                name="improvement"
                                value={stateActivity.improvement}
                                onChange={handleImprovementRadioChange}>
                      <FormControlLabel value="YES"
                                        control={<Radio color="primary"/>}
                                        label="Oui"/>
                      <FormControlLabel value="NO"
                                        control={<Radio color="primary"/>}
                                        label="Non"/>
                    </RadioGroup>
                  </FormControl>
                  {stateActivity.improvement === 'YES' &&
                  <>
                    <DescriptionList>
                      <DescriptionTerm>Si oui, lesquels ?</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="improvementDescription"
                            value={stateActivity.improvementDescription}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName,
                                value) => updateActivityDataAndDispatch(
                                fieldName,
                                value)}
                        />
                      </Description>
                    </DescriptionList>
                    <br/>
                  </>
                  }
                </>
                }
              </div>
            </Paper>
            }
            <Paper elevation={0} variant="outlined" className={classes.paper}>
              <AppBar className={classes.toolbar}
                      position="static"
                      color="default"
                      elevation={0}>
                <Toolbar>
                  <Typography variant="h6" gutterBottom component="div">
                    5. Attribution aux Outputs
                  </Typography>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={outputs}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    value={stateActivity.outputs}
                    onChange={handleOutputValueChange}
                    renderOption={(props, option) => (
                        <li {...props}>{option.name} {option.description}</li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Outputs auxquels cette activité contribue"
                        />
                    )}
                />
              </div>
            </Paper>
            {/*<Paper elevation={0} variant="outlined" className={classes.paper}>*/}
            {/*  <AppBar className={classes.toolbar}*/}
            {/*          position="static"*/}
            {/*          color="default"*/}
            {/*          elevation={0}>*/}
            {/*    <Toolbar>*/}
            {/*      <Typography variant="h6" gutterBottom component="div">*/}
            {/*        6. Sondage*/}
            {/*      </Typography>*/}
            {/*    </Toolbar>*/}
            {/*  </AppBar>*/}
            {/*  <div className={classes.contentWrapper}>*/}
            {/*    <TableContainer className={classes.tableContainer}>*/}
            {/*      <Table size="small" aria-label="collapsible table">*/}
            {/*        <TableHead>*/}
            {/*          <TableRow>*/}
            {/*            <TableCell width={'10%'}>Genre</TableCell>*/}
            {/*            <TableCell width={'10%'}>Age</TableCell>*/}
            {/*            <TableCell width={'10%'}>Nouvelles*/}
            {/*              compétences</TableCell>*/}
            {/*            <TableCell width={'10%'}>Mise en pratique</TableCell>*/}
            {/*            <TableCell width={'10%'}>Transfert des*/}
            {/*              compétences</TableCell>*/}
            {/*            <TableCell width={'10%'}>Déroulement</TableCell>*/}
            {/*            <TableCell width={'35%'}>Commentaires</TableCell>*/}
            {/*            <TableCell width={'5%'}>Action</TableCell>*/}
            {/*          </TableRow>*/}
            {/*        </TableHead>*/}
            {/*        <TableBody>*/}
            {/*          {stateActivity.survey && stateActivity.survey.surveyEntries.map(*/}
            {/*              (entry) => (*/}
            {/*                  <TableRow key={entry.id}>*/}
            {/*                    <TableCell width={'10%'}>{formatGender(*/}
            {/*                        entry.gender)}</TableCell>*/}
            {/*                    <TableCell width={'10%'}>{entry.age}</TableCell>*/}
            {/*                    <TableCell width={'10%'}>{formatAgreeType(*/}
            {/*                        entry.newCompetencies)}</TableCell>*/}
            {/*                    <TableCell width={'10%'}>{formatAgreeType(*/}
            {/*                        entry.newCompetenciesPractical)}</TableCell>*/}
            {/*                    <TableCell width={'10%'}>{formatAgreeType(*/}
            {/*                        entry.newCompetenciesColleagues)}</TableCell>*/}
            {/*                    <TableCell width={'10%'}>{formatAgreeType(*/}
            {/*                        entry.activityRating)}</TableCell>*/}
            {/*                    <TableCell*/}
            {/*                        width={'35%'}>{entry.comment}</TableCell>*/}
            {/*                    <TableCell width={'5%'}>*/}
            {/*                      <Tooltip title="Supprimer">*/}
            {/*                        <IconButton*/}
            {/*                            onClick={() => removeEntryFromSurvey(*/}
            {/*                                stateActivity.id, entry.id)}>*/}
            {/*                          <DeleteIcon className={classes.block}*/}
            {/*                                      color="inherit"/>*/}
            {/*                        </IconButton>*/}
            {/*                      </Tooltip>*/}
            {/*                    </TableCell>*/}
            {/*                  </TableRow>*/}
            {/*              ))}*/}
            {/*        </TableBody>*/}
            {/*      </Table>*/}
            {/*    </TableContainer>*/}
            {/*    <div className={classes.contentWrapper}>*/}
            {/*      {authState.role !== 'ROLE_READER' &&*/}
            {/*      <AddSurveyEntryDialog*/}
            {/*          confirmationHandler={addSurveyEntryConfirmationHandler}/>*/}
            {/*      }*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</Paper>*/}
            {/*<Paper elevation={0} variant="outlined" className={classes.paper}>*/}
            {/*  <AppBar className={classes.toolbar}*/}
            {/*          position="static"*/}
            {/*          color="default"*/}
            {/*          elevation={0}>*/}
            {/*    <Toolbar>*/}
            {/*      <Typography variant="h6" gutterBottom component="div">*/}
            {/*        7. Sondage de suivi*/}
            {/*      </Typography>*/}
            {/*    </Toolbar>*/}
            {/*  </AppBar>*/}
            {/*  <div className={classes.contentWrapper}>*/}
            {/*    <TableContainer className={classes.tableContainer}>*/}
            {/*      <Table size="small" aria-label="collapsible table">*/}
            {/*        <TableHead>*/}
            {/*          <TableRow>*/}
            {/*            <TableCell width={'10%'}>Genre</TableCell>*/}
            {/*            <TableCell width={'10%'}>Age</TableCell>*/}
            {/*            <TableCell width={'20%'}>Mise en pratique</TableCell>*/}
            {/*            <TableCell width={'20%'}>Transfert des*/}
            {/*              compétences</TableCell>*/}
            {/*            <TableCell width={'20%'}>*/}
            {/*              {stateActivity.followUpSurvey*/}
            {/*              && stateActivity.followUpSurvey.surveyEntries*/}
            {/*              && stateActivity.followUpSurvey.surveyEntries[0] &&*/}
            {/*              <>*/}
            {/*                {formatAdditionalQuestionHeader(*/}
            {/*                    stateActivity.followUpSurvey.surveyEntries)}*/}
            {/*              </>*/}
            {/*              }*/}
            {/*            </TableCell>*/}
            {/*            <TableCell width={'15%'}>Commentaires</TableCell>*/}
            {/*            <TableCell width={'5%'}>Action</TableCell>*/}
            {/*          </TableRow>*/}
            {/*        </TableHead>*/}
            {/*        <TableBody>*/}
            {/*          {stateActivity.followUpSurvey*/}
            {/*          && stateActivity.followUpSurvey.surveyEntries.map((entry) => (*/}
            {/*              <TableRow key={entry.id}>*/}
            {/*                <TableCell width={'10%'}>{formatGender(*/}
            {/*                    entry.gender)}</TableCell>*/}
            {/*                <TableCell width={'10%'}>{entry.age}</TableCell>*/}
            {/*                <TableCell width={'20%'}>{formatAgreeType(*/}
            {/*                    entry.newCompetenciesPractical)}</TableCell>*/}
            {/*                <TableCell width={'20%'}>{formatAgreeType(*/}
            {/*                    entry.newCompetenciesColleagues)}</TableCell>*/}
            {/*                <TableCell*/}
            {/*                    width={'20%'}>{formatAdditionalQuestionAnswer(*/}
            {/*                    entry.additionalQuestionAnswer)}</TableCell>*/}
            {/*                <TableCell width={'35%'}>{entry.comment}</TableCell>*/}
            {/*                <TableCell width={'5%'}>*/}
            {/*                  <Tooltip title="Supprimer">*/}
            {/*                    <IconButton*/}
            {/*                        onClick={() => removeEntryFromFollowUpSurvey(*/}
            {/*                            stateActivity.id, entry.id)}>*/}
            {/*                      <DeleteIcon className={classes.block}*/}
            {/*                                  color="inherit"/>*/}
            {/*                    </IconButton>*/}
            {/*                  </Tooltip>*/}
            {/*                </TableCell>*/}
            {/*              </TableRow>*/}
            {/*          ))}*/}
            {/*        </TableBody>*/}
            {/*      </Table>*/}
            {/*    </TableContainer>*/}
            {/*    <div className={classes.contentWrapper}>*/}
            {/*      { authState.role !== 'ROLE_READER' &&*/}
            {/*      <AddFollowUpSurveyEntryDialog*/}
            {/*          confirmationHandler={addFollowUpSurveyEntryConfirmationHandler}*/}
            {/*          lockQuestion={getLockQuestion}/>*/}
            {/*      }*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</Paper>*/}
            { authState && authState.role && authState.role === 'ROLE_SUPER' &&
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      6. Information sur l’émetteur
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={3}>
                    <DescriptionList>
                      <DescriptionTerm>Adresse e-mail</DescriptionTerm>
                      <Description>
                        <EditableInput
                            disabled
                            name="createdByEmail"
                            value={stateActivity.createdByEmail}
                            fullWidth={true}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                </div>
              </Paper>
            }
    </React.Fragment>
  }

  return renderData();

}

export default withStyles(styles)(Activity);