import React, {useContext} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import Navigator from './Navigator';
import ProtectedRoute from "./auth/ProtectedRoute";
import Dashboard from "./dashboard/Dashboard";
import Header from "./Header";
import UserSettings from "./settings/UserSettings";
import Activities from "./activities/Activities";
import {ThemeProvider, withStyles} from "@mui/styles";
import {
  createTheme,
  CssBaseline,
  Hidden,
  Typography
} from "@mui/material";
import Indicators from "./indicators/Indicators";
import TabbedOutputIndicators from "./indicators/TabbedOutputIndicators";
import DataProtection from "./DataProtection";
import Imprint from "./Imprint";
import Activity from "./activities/Activity";
import ProgrammeAnalysis from "./analysis/ProgrammeAnalysis";
import ModuleAnalysis from "./analysis/ModuleAnalysis";
import OutputAnalysis from "./analysis/OutputAnalysis";
import {UserContext} from "./auth/UserProvider";
import LocationSettings from "./settings/LocationSettings";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <a href={'/imprint'}>Mentions légales</a> | <a href={'/data-protection'}>Protection des données</a>
    </Typography>
  );
}

let theme = createTheme({
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(2, 1),
    background: '#eaeff1',
  },
  mainTabbed: {
    flex: 1,
    background: '#eaeff1',
    padding: 0,
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
};

function Paperbase(props) {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const authState = useContext(UserContext);

  const query = new URLSearchParams(document.location.search);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </nav>
        <div className={classes.app}>
          <BrowserRouter>
            <Switch>
              <ProtectedRoute exact path={["/dashboard", "/"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={authState.role !== 'ROLE_APP' ? 'Tableau de bord' : 'Nouvelles activités'}
                        showNavBar={false}  />
                <main className={classes.main}>
                  { authState.role === 'ROLE_APP' &&
                    <Activities state={'NEW'} />
                  }
                  { authState.role !== 'ROLE_APP' &&
                    <Dashboard />
                  }
                </main>
              </ProtectedRoute>

              <ProtectedRoute exact path="/activities/new">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Nouvelles activités"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <Activities state={'NEW'} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/activities", "/"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Activités"}
                        showNavBar={false} />
                <main className={classes.main}>
                  <Activities state={'REVIEWED'} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/activities/deleted">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Corbeille"}
                        showNavBar={false} />
                <main className={classes.main}>
                  <Activities state={'DELETED'} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/activities/unsurveyed">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Activités sans sondage de suivi"}
                        showNavBar={false} />
                <main className={classes.main}>
                  <Activities state={'UNSURVEYED'} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/activity/:activityId"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Activities"}
                        showNavBar={false} />
                <main className={classes.main}>
                  <Activity/>
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/indicators/programme"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Indicateurs du programme"}
                        showNavBar={false}
                        elevate={0} />
                <main className={classes.mainTabbed}>
                  <Indicators indicatorType="PROGRAMME" />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/indicators/module"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Indicateurs du module"}
                        showNavBar={false}
                        elevate={0} />
                <main className={classes.mainTabbed}>
                  <Indicators indicatorType="MODULE" />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/indicators/output"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Indicateurs des outputs"}
                        showNavBar={false}
                        elevate={0} />
                <main className={classes.mainTabbed}>
                  <TabbedOutputIndicators />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/program/analysis">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Analyse: Programme"}
                        showNavBar={false} />
                <main className={classes.main}>
                  <ProgrammeAnalysis />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/module/analysis">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Analyse: Module"}
                        showNavBar={false} />
                <main className={classes.main}>
                  <ModuleAnalysis />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/output/analysis">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Analyse: Output"}
                        showNavBar={true} />
                <main className={classes.mainTabbed}>
                  <OutputAnalysis />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/users"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Users"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <UserSettings />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={["/locations"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Lieux"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <LocationSettings />
                </main>
              </ProtectedRoute>
              <Route exact path={["/imprint"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Imprint"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <Imprint />
                </main>
              </Route>
              <Route exact path={["/data-protection"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Data Protection Policy"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <DataProtection />
                </main>
              </Route>
              <Route path='/authentication' component={() => {
                window.location.href =
                    `${process.env.REACT_APP_FIREBASE_REDIRECT_URL}?mode=${query.get('mode')}&oobCode=${query.get('oobCode')}&apiKey=${query.get('apiKey')}&lang=${query.get('lang')}`;
                return null;
              }}/>
            </Switch>
          </BrowserRouter>
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>
    </ThemeProvider>
  );
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);