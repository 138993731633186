import React from "react";
import { withStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const styles = () => ({
  contentWrapper: {
    width: '90%',
    margin: 'auto',
    marginLeft: 'auto',
    textAlign: 'left',
  },
  paper: {
    padding: '2rem',
  },
});

function DataProtection(props) {
  const { classes } = props;

  return (
      <>
        <div className={classes.contentWrapper}>
          <Paper elevation={3} className={classes.paper}>
            <Typography color="inherit" variant="h5" component="h5">
              Déclaration de confidentialité
            </Typography>
            <br/>
            <Typography color="inherit" variant="body1" component="div">
              La Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH attache une grande importance à une gestion responsable et transparente des données personnelles. Les utilisateurs trouveront ci-dessous des informations sur les sujets suivants :<br/>

              - Quelles sont les possibilités de contact concernant la protection des données à la GIZ ?<br/>
              - Quelles données sont traitées lors de la visite du site web ?<br/>
              - Quelles données sont traitées lorsque les utilisateurs* nous contactent, s'abonnent à la newsletter ou aux communiqués de presse ou utilisent d'autres offres en ligne de la GIZ ?<br/>
              - Les possibilités de s'opposer à l'enregistrement des données.<br/>
              - Quels sont vos droits à notre égard ? <br/>
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Responsable et délégué à la protection des données
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Le responsable du traitement des données est la Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH.<br/>
              <br/>
              Adresse:<br/>
              Friedrich-Ebert-Allee 32 36, 53113 Bonn<br/>
              Dag-Hammarskjöld-Weg 1-5, 65760 Eschborn<br/>
              <br/>
              Contact:<br/>
              <a href="mailto:info@giz.de">info@giz.de</a><br/>
              <br/>
              Pour toute question concrète sur la protection de vos données, veuillez contacter le responsable de la protection des données de la GIZ : <a href="mailto:datenschutzbeauftragter@giz.de">datenschutzbeauftragter@giz.de</a>


            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Information sur la collecte de données à caractère personnel
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Généralités<br/>
              Lors de la visite du site web de la GIZ, le navigateur utilisé transmet automatiquement des données qui sont enregistrées dans un fichier journal. La GIZ elle-même ne traite que les données qui sont techniquement nécessaires pour afficher correctement le site web et en assurer la stabilité et la sécurité.<br/>
              <br/>
              Entre autres, la page consultée, l'adresse IP de l'appareil utilisé, la page à partir de laquelle l'utilisateur* a été redirigé ainsi que la date et l'heure de la consultation sont enregistrées pour chaque accès. Vous trouverez <a href="https://www.giz.de/de/html/89810.html"><u>ici</u></a> une liste détaillée des données enregistrées. Les données du fichier journal sont supprimées au bout de cinq jours.<br/>
              <br/>
              Cookies<br/>
              Lors de la visite du site web de la GIZ, de petits fichiers texte, appelés cookies, sont enregistrés sur l'ordinateur du visiteur ou de la visiteuse. Ils servent à rendre l'offre Internet globalement plus conviviale et plus efficace. Les cookies ne peuvent pas exécuter de programmes ni transmettre de virus à votre ordinateur. Le site web de la GIZ utilise des cookies qui sont automatiquement supprimés dès que le navigateur dans lequel la page est affichée est fermé (cookies temporaires ou de session).<br/>
              <br/>
              Service d'analyse etracker (analyse des utilisateurs)<br/>
              La GIZ utilise sur son site web le service d'analyse web "etracker" pour analyser les données d'utilisation. Ce service est exploité par la société etracker GmbH de Hambourg. Les données sont enregistrées et analysées de manière totalement anonyme.<br/>
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Traitement des données à caractère personnel lors de la prise de contact
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Lorsque les utilisateurs prennent contact avec nous, les données fournies sont traitées afin de pouvoir répondre à leur demande. Les possibilités de contact suivantes existent: <br/>

              - E-mail<br/>
              - Téléphone<br/>
              - Lettre<br/>
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Mise à disposition d'informations
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Le site web de la GIZ propose des lettres d'information et un abonnement à la presse. Le traitement des données personnelles dépend du type de mise à disposition des informations.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Autres offres web
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Lorsque vous consultez d'autres offres web de la GIZ, par exemple des offres d'emploi ou des inscriptions à des formations, vous devez saisir des données personnelles pour le traitement ultérieur. Dans ce cas, les déclarations de protection des données adaptées à l'offre concernée s'appliquent.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Traitement des données personnelles dans le cadre de l'utilisation des réseaux sociaux
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              La GIZ offre sur son site web la possibilité de visiter les présences de l'entreprise sur les réseaux sociaux et les plateformes telles que X (Twitter), LinkedIn, YouTube et Facebook. Ces présences en ligne sont exploitées pour interagir avec les utilisateurs actifs sur ces sites et pour informer sur les projets et les prestations. Un clic sur le logo d'un réseau social mène à la présence de la GIZ sur le réseau en question. Lors de la visite des plates-formes, aucune donnée personnelle n'est collectée, utilisée et enregistrée par la GIZ, mais par les exploitants des réseaux sociaux respectifs. Cela se produit même si les visiteurs n'ont pas de profil sur le réseau social en question. Les différents processus de traitement des données et leur étendue varient en fonction de l'opérateur du réseau social concerné. La GIZ n'a aucune influence sur la collecte des données et leur utilisation ultérieure par les exploitants des réseaux sociaux. Nous ne pouvons pas savoir dans quelle mesure, à quel endroit et pour quelle durée les données sont stockées, dans quelle mesure les réseaux respectent les obligations de suppression existantes, quelles évaluations et quels liens sont effectués avec les données et à qui les données sont transmises. Lors de la consultation d'une présence de la GIZ sur les médias sociaux, les conditions d'utilisation et les déclarations de protection des données des exploitants respectifs s'appliquent. Les adresses de contact et les liens vers les déclarations de protection des données des médias sociaux dans lesquels la GIZ exploite des sites sont fournis par la GIZ.

              Plus d'informations <a href="https://www.giz.de/de/html/89811.html"><u>ici</u></a>.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Transmission à des tiers
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              La GIZ ne transmet pas de données personnelles à des tiers, à moins qu'elle n'y soit légalement obligée ou autorisée par des dispositions légales.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Transmissions de données à l'étranger
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              La GIZ ne transmet pas de données personnelles à des pays tiers. Lors de l'utilisation de médias sociaux, les dispositions relatives à la protection des données des fournisseurs respectifs s'appliquent.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Durée de conservation des données
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Les données des utilisateurs ne sont pas conservées plus longtemps que nécessaire au regard des finalités pour lesquelles elles sont traitées ou en vertu d'obligations légales de conservation.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Sécurité informatique des données des utilisateurs
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              La protection des données personnelles est une préoccupation majeure de la GIZ. C'est pourquoi des mesures de sécurité techniques et organisationnelles veillent à ce que les données soient protégées contre les manipulations accidentelles et intentionnelles, la suppression accidentelle et l'accès non autorisé. Ces mesures sont actualisées en fonction des développements techniques et adaptées en permanence aux risques.
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Mention des droits des utilisateurs
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              En tant que visiteur du site web de la GIZ, vous avez le droit de,<br/>
              <br/>
              - Demander des informations sur les données que nous avons enregistrées concernant votre visite (art. 15 RGPD),<br/>
              - Demander la rectification des données que nous avons enregistrées concernant votre visite (art. 16 RGPD),<br/>
              - Demander l'effacement des données que nous avons enregistrées concernant votre visite (art. 17 RGPD),<br/>
              - Demander la limitation du traitement des données que nous avons enregistrées concernant votre visite (art. 18 RGPD),<br/>
              - S’opposer à l'enregistrement des données concernant votre visite lorsque les données personnelles sont traitées sur la base de l'art. de s'opposer à l'enregistrement des données relatives à votre visite lorsque des données à caractère personnel sont traitées sur la base de l'article 6, paragraphe 1, alinéa 1, lettres f et e du RGPD (article 21 du RGPD),<br/>
              - De recevoir du responsable du traitement des données relatives à votre visite dans un format courant et lisible par machine afin de les transmettre, le cas échéant, à un autre responsable du traitement. (Droit à la portabilité des données, art. 20 RGPD),<br/>
              - o	De retirer votre consentement, dans la mesure où le traitement des données relatives à votre visite a été effectué sur la base d'un consentement (art. 6, par. 1 a) RGPD). La légalité du traitement sur la base du consentement donné n'est pas affectée jusqu'à la réception de la révocation.<br/>
              <br/>
              Les utilisateurs ont également le droit de déposer une plainte auprès de l'autorité de surveillance compétente en matière de protection des données, conformément à l'article 77 du RGPD. L'autorité compétente est le Commissaire fédéral à la protection des données et à la liberté d'information (BfDI - Behörde ist der Bundesbeauftragte für den Datenschutz und die Informationsfreiheit).<br/>
              <br/>
              <br/>
              Date: Oktober 2020
            </Typography>
          </Paper>
        </div>
      </>
  );
}

export default withStyles(styles)(DataProtection);