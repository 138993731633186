import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import useFetchData from "../../utils/useFetchData";
import {getProgrammeAnalysis} from "../../utils/api";
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chart from "react-apexcharts";

const styles = () => ({
  paper: {
    maxWidth: '95%',
    minWidth: '70%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  totalProgress: {
    marginTop: 'auto',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

const arrSum = arr => arr.reduce((a, b) => a + b.numericValue, 0);

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgressWithLabel);

function LinearProgressWithLabel(props) {
  return (
      <Box display="flex" alignItems="center">
        <Box width="90%" mr={1}>
          <LinearProgress sx={{height: 24}} color={'primary'} variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {props.current.toLocaleString()} / {props.target.toLocaleString()} ({Math.round(((props.current / props.target) + Number.EPSILON)  * 100)}%)
          </Typography>
        </Box>
      </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function ProgrammeAnalysis(props) {
  const { data, isLoading, isError } = useFetchData(getProgrammeAnalysis);
  const { classes } = props;

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !data) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    const pieChartDataSeries = [];
    const pieChartDataOptions = {
      labels: [],
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
      },
      dataLabels: {
        style: {
          fontSize: '24px',
        },
      },
      colors: [
        '#003f5c',
        '#2f4b7c',
        '#665191',
        '#a05195',
        '#d45087',
        '#f95d6a',
        '#ff7c43',
        '#ffa600'
      ],
    };

    if (data.analysisOverallSummary) {
      pieChartDataSeries.push(data.analysisOverallSummary.numberSeminars);
      pieChartDataOptions.labels.push('Séminaires');
      pieChartDataSeries.push(data.analysisOverallSummary.numberWorkshops);
      pieChartDataOptions.labels.push('Ateliers');
      pieChartDataSeries.push(data.analysisOverallSummary.numberPublications);
      pieChartDataOptions.labels.push('Publications');
      pieChartDataSeries.push(data.analysisOverallSummary.numberCampaigns);
      pieChartDataOptions.labels.push('Campagnes');
    }

    return (
        <>
          <Grid className={classes.contentWrapper} container spacing={3}>
            <Grid item xs={8}>
              <Paper style={{height: '100%', minHeight: '400px'}} className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Types d'activités
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Grid className={classes.contentWrapper} container spacing={3}>
                  <Grid item xs={4}>
                    <List>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisOverallSummary.numberSeminars} séminaire(s)`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisOverallSummary.numberWorkshops} atelier(s)`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisOverallSummary.numberPublications} publication(s)`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisOverallSummary.numberCampaigns} campagne(s) médiatique(s)`}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={8}>
                    <Chart options={pieChartDataOptions} series={pieChartDataSeries} type="pie" />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{height: '100%', minHeight: '400px'}} className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Statistiques
                    </Typography>
                  </Toolbar>
                </AppBar>
                <List>
                  { data.analysisOverallStatistics.totalParticipants > 0 &&
                  <ListItem>
                    <ListItemText
                        primary={`${data.analysisOverallStatistics.totalParticipants} participants`}
                    />
                  </ListItem>
                  }
                  { data.analysisOverallStatistics.femaleParticipants > 0 &&
                  <ListItem>
                    <ListItemText
                        primary={`${data.analysisOverallStatistics.femaleParticipants} participants femmes`}
                    />
                  </ListItem>
                  }
                  { data.analysisOverallStatistics.maleParticipants > 0 &&
                  <ListItem>
                    <ListItemText
                        primary={`${data.analysisOverallStatistics.maleParticipants} participants hommes`}
                    />
                  </ListItem>
                  }
                  { data.analysisOverallStatistics.youngParticipants > 0 &&
                  <ListItem>
                    <ListItemText
                        primary={`${data.analysisOverallStatistics.youngParticipants} participants jeunes`}
                    />
                  </ListItem>
                  }
                </List>
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper style={{height: '100%'}} className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      État d'avancement
                    </Typography>
                  </Toolbar>
                </AppBar>
                { data.indicators.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((indicator) => (
                    <div key={indicator.id} className={classes.contentWrapper}>
                      <Typography variant="overline" align={'center'} color="textPrimary">
                        {indicator.name}
                      </Typography>
                      <div style={{ fontSize: 14, marginBottom: '1rem' }}>
                        {indicator.description}
                      </div>
                      <StyledLinearProgress
                          value={Math.round(arrSum(indicator.indicatorValues) / indicator.targetValueNumeric * 100)}
                          current={indicator.startValueNumeric + arrSum(indicator.indicatorValues)}
                          target={indicator.targetValueNumeric}/>
                    </div>
                ))}
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{height: '100%'}} className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Progrès total
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Typography variant="h2" style={{marginTop: '1rem'}} align={'center'} color="textPrimary" className={classes.totalProgress}>
                  {data.totalPercentage}%
                </Typography>
              </Paper>
            </Grid>
            {/*{ data.surveyStatistics &&*/}
            {/*  <SurveyStatistics surveyStatistics={data.surveyStatistics}/>*/}
            {/*}*/}
          </Grid>
        </>
    );
  };

  return renderData();
}

ProgrammeAnalysis.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProgrammeAnalysis);
