import { useEffect, useState, useCallback, useRef } from 'react';

function useFetchData(apiCall, params = null, initialData = {}) {
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const cancelRequest = useRef();

  const fetchData = useCallback(
    async parameters => {
      let requestParams = parameters;
      if (!requestParams) {
        requestParams = params;
      }
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await apiCall(requestParams);
        if (!cancelRequest.current) {
          if (result.data && Object.keys(result.data).length > 0) {
            setData(result.data);
          } else {
            setIsError(true);
          }
          setIsLoading(false);
        }
      } catch (error) {
        if (!cancelRequest.current) {
          setIsError(true);
          setIsLoading(false);
        }
      }
    },
    [apiCall, params]
  );

  useEffect(() => {
    cancelRequest.current = false;
    fetchData(params);

    return () => {
      cancelRequest.current = true;
    };
  }, [fetchData, params]);

  return { data, isLoading, isError, fetchData };
}

export default useFetchData;
