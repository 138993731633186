import React from "react";
import { withStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const styles = () => ({
  contentWrapper: {
    width: '90%',
    margin: 'auto',
    marginLeft: 'auto',
    textAlign: 'left',
  },
  paper: {
    padding: '2rem',
  },
});

function Imprint(props) {
  const { classes } = props;

  return (
      <>
        <div className={classes.contentWrapper}>
          <Paper elevation={3} className={classes.paper}>
            <Typography color="inherit" variant="h5" component="h5">
              Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Sièges de la société :
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Bonn und Eschborn, Allemagne<br/>
              <br/>
              Friedrich-Ebert-Allee 32 + 36<br/>
              53113 Bonn, Allemagne<br/>
              T +49 228 44 60-0<br/>
              F +49 228 44 60-17 66<br/>
              <br/>
              Dag-Hammarskjöld-Weg 1 - 5<br/>
              65760 Eschborn, Allemagne<br/>
              T +49 6196 79-0<br/>
              F +49 6196 79-11 15<br/>
              <br/>
              E info@giz.de<br/>
              I www.giz.de<br/>
            </Typography>
            <br/>
            <Typography color="inherit" variant="body1" component="div">
              Tribunal d’instance (Amtsgericht) Bonn, Allemagne: <br/>
              N° d’immatriculation au registre du commerce : HRB 18384<br/>
              Tribunal d’instance (Amtsgericht) Francfort-sur-le-Main, Allemagne: <br/>
              N° d’immatriculation au registre du commerce : HRB 12394<br/>
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Président du conseil de surveillance
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Jochen Flasbarth, Secrétaire d’État
            </Typography>
            <br/>
            <Typography color="inherit" variant="h6" component="h6">
              Directoire
            </Typography>
            <Typography color="inherit" variant="body1" component="div">
              Thorsten Schäfer-Gümbel<br/>
              (Président du directoire)<br/>
              Ingrid-Gabriela Hoven<br/>
              (Vice-présidente du directoire)<br/>
              Anna Sophie Herken
            </Typography>
          </Paper>
        </div>
      </>
  );
}

export default withStyles(styles)(Imprint);