export function formatActivityType(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'SEMINAR':
      return 'Séminaire / Formation';
    case 'WORKSHOP':
      return 'Atelier / Workshop';
    case 'PUBLICATION':
      return 'Publication';
    case 'CAMPAIGN':
      return 'Campagne médiatique';
    case 'OTHER':
      return 'Autre';
    default:
      return '';
  }
}

export function getInTextActivityType(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'SEMINAR':
      return 'séminaire';
    case 'WORKSHOP':
      return 'atelier';
    case 'PUBLICATION':
      return 'publication';
    case 'CAMPAIGN':
      return 'campagne';
    case 'OTHER':
      return 'autre';
    default:
      return '';
  }
}