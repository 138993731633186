import React, {useState} from 'react';
import {withStyles} from "@mui/styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function AddTargetValueDialog(props) {
  const { classes, confirmationHandler } = props;
  const [targetValueType, setTargetValueType] = useState();
  const [numericValue, setNumericValue] = useState();
  const [showOthersType, setShowOthersType] = useState(false);
  const [othersType, setOthersType] = useState('');
  const [open, setOpen] = useState(false);

  const [numericValueFieldHasError, setNumericValueFieldHasError] = useState(false);
  const [numericValueFieldHelperText, setNumericValueFieldHelperText] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    let flexibleTargetValue = targetValueType;
    if (othersType !== '') {
      flexibleTargetValue = othersType;
    }

    confirmationHandler(numericValue, flexibleTargetValue);
    handleClose();
  };

  const clearFormData = () => {
    setTargetValueType('');
    setNumericValue('');
    setOthersType('');
  };

  const onTargetValueTypeChange = (event) => {
    let value = event.target.value;

    if (value === 'Autres') {
      setShowOthersType(true);
    } else {
      setShowOthersType(false);
    }

    setTargetValueType(event.target.value);
  };

  const onNumericValueChange = (e) => {
    if (e.target.value >= 0) {
      setNumericValueFieldHasError(false);
      setNumericValueFieldHelperText('');
      setNumericValue(e.target.value);
    } else {
      setNumericValueFieldHasError(true);
      setNumericValueFieldHelperText('Pas une nombre valide');
    }
  };

  const onOthersTypeChange = (e) => {
    setOthersType(e.target.value);
  };

  return (
      <div>
        <Button variant="outlined" color="primary" sx={{fontSize: 14}} onClick={handleClickOpen}>
          Ajouter nouvelles cibles prévues
        </Button>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Ajouter nouvelles cibles prévues</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Veuillez saisir les informations nécessaires
            </DialogContentText>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel shrink id="target-value-select-label">
                Type
              </InputLabel>
              <Select
                  labelId="target-value-select"
                  id="target-value-select"
                  value={targetValueType}
                  onChange={onTargetValueTypeChange}
                  className={classes.selectEmpty}
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                <MenuItem value="Ménages / Population locale">Ménages / Population locale</MenuItem>
                <MenuItem value="Société civile">Société civile</MenuItem>
                <MenuItem value="Médias">Médias</MenuItem>
                <MenuItem value="Syndicats">Syndicats</MenuItem>
                <MenuItem value="Coopératives minières">Coopératives minières</MenuItem>
                <MenuItem value="Coopératives de femmes">Coopératives de femmes</MenuItem>
                <MenuItem value="Petites et moyennes entreprises (PME)">Petites et moyennes entreprises (PME)</MenuItem>
                <MenuItem value="Grandes Entreprises / sociétés minières industrielles">Grandes Entreprises / sociétés minières industrielles</MenuItem>
                <MenuItem value="Diplômés / Stagiaires">Diplômés / Stagiaires</MenuItem>
                <MenuItem value="Autorités locales">Autorités locales</MenuItem>
                <MenuItem value="Autorités nationales">Autorités nationales</MenuItem>
                <MenuItem value="Organisations / institutions internationales">Organisations / institutions internationales</MenuItem>
                <MenuItem value="Partenariat multi-acteurs">Partenariat multi-acteurs</MenuItem>
                <MenuItem value="Autres">Autres</MenuItem>
                </Select>
            </FormControl>
            { showOthersType &&
              <>
                <br/>
                <FormControl className={classes.formControl}>
                  <TextField
                      autoFocus
                      margin="normal"
                      id="others-type"
                      label="Autre type"
                      type="text"
                      value={othersType}
                      onChange={onOthersTypeChange}
                      fullWidth
                  />
                </FormControl>
              </>
            }

            <br/>
            <FormControl className={classes.formControl}>
              <TextField
                  autoFocus={false}
                  margin="normal"
                  id="name"
                  label="Nombre"
                  type="number"
                  value={numericValue}
                  onChange={onNumericValueChange}
                  error={numericValueFieldHasError}
                  helperText={numericValueFieldHelperText}
                  inputProps={{ min: "0", step: "1" }}
                  fullWidth
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} size="small" color="primary">
              Annuler
            </Button>
            <Button onClick={handleConfirm} size="small" color="primary">
              Créer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddTargetValueDialog)