import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  createLocation, getLocations, updateLocation
} from "../../utils/api";
import {CircularProgress} from "@mui/material";
import {withStyles} from "@mui/styles";
import {DataGrid} from "@mui/x-data-grid";
import {UserContext} from "../auth/UserProvider";
import AddLocationDialog from "../dialogs/AddLocationDialog";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  }
});


function LocationSettings(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [userList, setUserList] = useState('');
  const [locationBuffer, setLocationBuffer] = useState({});

  const authState = useContext(UserContext);

  const addConfirmationHandler =
      async (name, lat, lng) => {
        await createLocation({
          'englishName': name,
          'lat': +lat,
          'lng': +lng
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, name, lat, lng) => {
        await updateLocationAndDispatch(entityId, {
          'englishName': name,
          'lat': +lat,
          'lng': +lng
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const fetchData =
      async (loading) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getLocations();
          const rolesData = result.data;

          if (!cancelRequest.current) {
            if (rolesData && Object.keys(rolesData).length > 0) {
              setUserList(rolesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, []);

  const updateLocationAndDispatch = async (locationId, fields) => {
    try {
      let buffer = {
        ...locationBuffer
      };

      setLocationBuffer(buffer);

      const { data: data } = await updateLocation(
          locationId,
          fields
      );

      if (!cancelRequest.current) {
        setLocationBuffer(data);
      }
    } catch (error) {
      setLocationBuffer(locationBuffer);
    }
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              Accès refusé !
            </div>
          </>
      );
    }

    if (isLoading || !userList) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <DataGrid
              hideFooter={true}
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={userList}
              columns={[
                { field: 'englishName', headerName: 'Nom', flex: 1 },
                { field: 'lat', headerName: 'Latitude', flex: 1 },
                { field: 'lng', headerName: 'Longitude', flex: 1 },
                {
                  field: 'actions',
                  headerName: 'Action',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <AddLocationDialog
                        confirmationHandler={updateConfirmationHandler}
                        action="edit"
                        entity={params.row} />;
                  }
                }
              ]}
              density="compact"
              rowCount={userList.length}
          />
          {authState.role !== 'ROLE_READER' &&
            <AddLocationDialog
                confirmationHandler={addConfirmationHandler}/>
          }
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(LocationSettings);