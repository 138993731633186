import React, {useEffect, useRef, useState} from 'react';
import {
  CircularProgress,
  Grid,
  Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Typography
} from "@mui/material";
import {withStyles} from "@mui/styles";
import * as PropTypes from "prop-types";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {getStatistics} from "../../utils/api";
import {formatTime} from "../../utils/timeHelper";
import Chart from "react-apexcharts";
import {formatActivityType} from "../../utils/activityTypeHelper";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
});

MapContainer.propTypes = {
  style: PropTypes.shape(
      {paddingTop: PropTypes.string, height: PropTypes.number}),
  scrollWheelZoom: PropTypes.bool,
  zoom: PropTypes.number,
  center: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node
};

function Dashboard(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [statistics, setStatistics] = useState('');

  const cancelRequest = useRef();

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getStatistics();
          const statisticsData = result.data;

          if (!cancelRequest.current) {
            if (statisticsData && Object.keys(statisticsData).length > 0) {
              setStatistics(statisticsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const position = [-4.038333,	21.758664];

  const pieChartDataSeries = [];
  const pieChartDataOptions = {
    labels: [],
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '14px',
    },
    dataLabels: {
      style: {
        fontSize: '24px',
      },
    },
    colors: [
      '#003f5c',
      '#2f4b7c',
      '#665191',
      '#a05195',
      '#d45087',
      '#f95d6a',
      '#ff7c43',
      '#ffa600'
    ],
  };

  if (statistics.activityTypes) {
    statistics.activityTypes.map((type) => {
      pieChartDataSeries.push(type.amount);
      pieChartDataOptions.labels.push(formatActivityType(type.activityType));
    })
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              Accès refusé !
            </div>
          </>
      );
    }

    if (isLoading || !statistics) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" className={classes.paper}>
            <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
              Carte du pays - Activités en cours et prévues
            </Typography>
            <MapContainer center={position} zoom={5} scrollWheelZoom={false} style={{height: 450, paddingTop: '2rem'}}>
              <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              { statistics.locationStatistics && statistics.locationStatistics.map((location) => (
                  <>
                    { location.lat && location.lng &&
                    <Marker
                        position={[location.lat, location.lng]} key={location.id}>
                      <Popup>
                        <div style={{fontSize: 14, fontWeight: 'bold'}}>{location.englishName}</div>
                        {location.pastActivities && location.pastActivities.length > 0 &&
                        <strong>Activités passées:</strong>
                        }
                        <ul>
                          {location.pastActivities && location.pastActivities.map((a) => (
                              <li><a href={'/activity/' + a.id}>{`${a.name} (${formatTime(a.date)})`}</a></li>
                          ))}
                        </ul>
                        {location.ongoingActivities && location.ongoingActivities.length > 0 &&
                        <strong>En cours:</strong>
                        }
                        <ul>
                          {location.ongoingActivities && location.ongoingActivities.map((a) => (
                              <li><a href={'/activity/' + a.id}>{`${a.name} (${formatTime(a.date)})`}</a></li>
                          ))}
                        </ul>
                        {location.activitiesPlanned && location.activitiesPlanned.length > 0 &&
                        <strong>À venir:</strong>
                        }
                        <ul>
                          {location.activitiesPlanned && location.activitiesPlanned.map((a) => (
                              <li><a href={'/activity/' + a.id}>{`${a.name} (${formatTime(a.date)})`}</a></li>
                          ))}
                        </ul>
                      </Popup>
                    </Marker>
                    }
                  </>
              ))}
            </MapContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{height: '100%'}} variant="outlined"
                 className={classes.paper}>
            <Typography variant="h5" color="textPrimary"
                        style={{paddingBottom: '1rem'}}>
              Types d'activités
            </Typography>
            <Chart options={pieChartDataOptions} series={pieChartDataSeries} type="pie" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{height: '100%'}} variant="outlined"
                 className={classes.paper}>
            <Typography variant="h5" color="textPrimary"
                        style={{paddingBottom: '1rem'}}>
              Activités récemment finalisées
            </Typography>
            {statistics.pastActivities.length > 0 &&
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell width={'40%'}>Nom</TableCell>
                    <TableCell width={'30%'}>Type</TableCell>
                    <TableCell width={'30%'}>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statistics.pastActivities
                  && statistics.pastActivities.map(
                      (activity) => (
                          <TableRow key={activity.id}>
                            <TableCell component="th"
                                       scope="row"><a href={'/activity/' + activity.id}>{activity.name}</a></TableCell>
                            <TableCell>{formatActivityType(
                                activity.activityType)}</TableCell>
                            <TableCell>{formatTime(
                                activity.date)}</TableCell>
                          </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            }
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
            <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
              Prochaines activités
            </Typography>
            {statistics.upcomingActivities.length > 0 &&
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell width={'40%'}>Nom</TableCell>
                    <TableCell width={'30%'}>Type</TableCell>
                    <TableCell width={'30%'}>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statistics.upcomingActivities
                  && statistics.upcomingActivities.map(
                      (activity) => (
                          <TableRow key={activity.id}>
                            <TableCell component="th"
                                       scope="row"><a href={'/activity/' + activity.id}>{activity.name}</a></TableCell>
                            <TableCell>{formatActivityType(
                                activity.activityType)}</TableCell>
                            <TableCell>{formatTime(
                                activity.date)}</TableCell>
                          </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  };

  return renderData();
}

export default withStyles(styles)(Dashboard);