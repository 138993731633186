import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBnTbcyqeLnfFLXlxZq8TkqTjWb4D579UA",
  authDomain: "dism-2.firebaseapp.com",
  projectId: "dism-2",
  storageBucket: "dism-2.appspot.com",
  messagingSenderId: "781756894564",
  appId: "1:781756894564:web:c49ba81608899c8a0b7e71"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();