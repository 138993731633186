import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {searchActivities} from "../../utils/api";
import {Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import Typography from "@mui/material/Typography";

export default function AddIndicatorValueDialog(props) {
  const { indicator, entity, confirmationHandler, action = 'create', disableActivityUpdate = false } = props;
  const [open, setOpen] = useState(false);
  const [indicatorValueDescription, setIndicatorValueDescription] = useState('');
  const [indicatorValueActivity, setIndicatorValueActivity] = useState(undefined);
  const [indicatorValueComment, setIndicatorValueComment] = useState('');
  const [numericValue, setNumericValue] = useState(0);
  const [secondaryNumericValue, setSecondaryNumericValue] = useState(0);
  const [tertiaryNumericValue, setTertiaryNumericValue] = useState(0);
  const [majorMilestone, setMajorMilestone] = useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
    setIndicatorValueDescription(entity && entity.description ? entity.description : '');
    setIndicatorValueActivity(entity && entity.activity ? entity.activity : undefined);
    setIndicatorValueComment(entity && entity.comment ? entity.comment : '');
    setNumericValue(entity && entity.numericValue ? entity.numericValue : 0);
    setSecondaryNumericValue(entity && entity.secondaryNumericValue ? entity.secondaryNumericValue : 0);
    setTertiaryNumericValue(entity && entity.tertiaryNumericValue ? entity.tertiaryNumericValue : 0);
    setTertiaryNumericValue(entity && entity.majorMilestone ? entity.majorMilestone : undefined);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    action === 'create' ?
      confirmationHandler(
          indicator.id,
          indicatorValueDescription,
          indicatorValueActivity,
          indicatorValueComment,
          numericValue,
          indicator && indicator.secondaryTargetValueNumeric ? secondaryNumericValue : null,
          indicator && indicator.tertiaryTargetValueNumeric ? tertiaryNumericValue : null,
          majorMilestone) :
        confirmationHandler(
            entity.id,
            indicator.id,
            indicatorValueDescription,
            indicatorValueActivity,
            indicatorValueComment,
            numericValue,
            indicator && indicator.secondaryTargetValueNumeric ? secondaryNumericValue : null,
            indicator && indicator.tertiaryTargetValueNumeric ? tertiaryNumericValue : null,
            majorMilestone);
    handleClose();
  };

  const clearFormData = () => {
    setIndicatorValueDescription('');
    setIndicatorValueActivity(undefined);
    setIndicatorValueComment('');
    setNumericValue(0);
    setSecondaryNumericValue(0);
    setTertiaryNumericValue(0);
    setMajorMilestone(undefined);
  };

  const onIndicatorValueDescriptionChange = (e) => {
    setIndicatorValueDescription(e.target.value);
  };

  const onIndicatorValueCommentChange = (e) => {
    setIndicatorValueComment(e.target.value);
  };

  const onNumericValueChange = (e) => {
    setNumericValue(e.target.value);
  };

  const onSecondaryNumericValueChange = (e) => {
    setSecondaryNumericValue(e.target.value);
  };

  const onTertiaryNumericValueChange = (e) => {
    setTertiaryNumericValue(e.target.value);
  };

  const changeInput = value => {
    setIndicatorValueActivity(value);
  };

  return (
      <div>
        {action === 'create' &&
          <Button variant="outlined" color="primary" sx={{fontSize: 14}} onClick={handleClickOpen}>
            Ajouter { indicator && indicator.targetValueNumeric === 1 ? 'jalon' : 'entrée'}
          </Button>
        }
        { action === 'edit' &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        }
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Ajouter' : 'Modifier'} { indicator && indicator.targetValueNumeric === 1 ? 'jalon' : 'entrée'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Veuillez saisir les informations nécessaires
            </DialogContentText>
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Description"
                type="text"
                value={indicatorValueDescription}
                onChange={onIndicatorValueDescriptionChange}
                fullWidth
                variant="outlined"
            />
            { !disableActivityUpdate &&
              <BasicAutocomplete handleChange={changeInput} searchFct={searchActivities} currentValue={indicatorValueActivity} label={'Activité'} property={'name'}/>
            }
            <TextField
                autoFocus={false}
                margin="normal"
                id="name"
                label="Commentaire"
                type="text"
                value={indicatorValueComment}
                onChange={onIndicatorValueCommentChange}
                fullWidth
                variant="outlined"
            />
            { indicator && indicator.targetValueNumeric === 1 &&
              <FormGroup>
                <Typography variant="body1" color="textPrimary">
                  Un jalon majeur a été atteint
                </Typography>
                <FormControl variant="filled" style={{maxWidth: '100%'}}>
                  <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Jalon
                  </InputLabel>
                  <Select
                      labelId="milestone-select"
                      id="milestone-select"
                      value={majorMilestone}
                      onChange={(event) => {setMajorMilestone(event.target.value)}}
                  >
                    { indicator.milestones && indicator.milestones.map((milestone) => (
                          <MenuItem value={milestone.priority}>{`(${milestone.priority}) ${milestone.description}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel control={<Checkbox checked={numericValue !== 0} onChange={(event) => setNumericValue(event.target.checked ? 1 : 0)} />} label="Marquer l'indicateur comme étant complété" />
              </FormGroup>
            }
            { indicator && indicator.targetValueNumeric !== 1 &&
              <TextField
                  autoFocus={false}
                  margin="normal"
                  id="name"
                  label="Nombre"
                  value={numericValue}
                  onChange={onNumericValueChange}
                  type="number"
                  variant="outlined"
              />
            }
            { indicator && indicator.secondaryTargetValueNumeric &&
                <>
                  <br/>
                  <TextField
                      autoFocus={false}
                      margin="normal"
                      id="name"
                      label={`Nombre secondaire (${indicator.secondaryTargetValueDescription})`}
                      value={secondaryNumericValue}
                      onChange={onSecondaryNumericValueChange}
                      type="number"
                      variant="outlined"
                  />
                </>
            }
            { indicator && indicator.tertiaryTargetValueNumeric &&
              <>
                <br/>
                <TextField
                    autoFocus={false}
                    margin="normal"
                    id="name"
                    label={`Nombre tertiaire (${indicator.tertiaryTargetValueDescription})`}
                    value={tertiaryNumericValue}
                    onChange={onTertiaryNumericValueChange}
                    type="number"
                    variant="outlined"
                />
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" size="small">
              Annuler
            </Button>
            <Button onClick={handleConfirm} color="primary" size="small">
              { action === 'create' ? 'Ajouter' : 'Modifier'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}