import React from 'react';
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/RestoreFromTrash";
import ReviewedIcon from "@mui/icons-material/AssignmentTurnedIn";
import {
  Button,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from "@mui/material";
import {withStyles} from "@mui/styles";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function ConfirmActionDialog(props) {
  const { classes, confirmationHandler, action } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    confirmationHandler();
    handleClose();
  };

  return (
      <>
        { action === 'reviewed' &&
        <Tooltip title="Marquer comme validé">
          <IconButton onClick={handleClickOpen}>
            <ReviewedIcon sx={{ fontSize: 24 }} className={classes.block} color="inherit"/>
          </IconButton>
        </Tooltip>
        }
        { action === 'delete' &&
        <Tooltip title="Supprimer">
          <IconButton  onClick={handleClickOpen}>
            <DeleteIcon sx={{ fontSize: 24 }} className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        }
        { action === 'harddelete' &&
        <Tooltip title="Supprimer définitivement">
          <IconButton onClick={handleClickOpen}>
            <DeleteIcon sx={{ fontSize: 24 }} className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        }
        { action === 'restore' &&
        <Tooltip title="Restaurer">
          <IconButton onClick={handleClickOpen}>
            <RestoreIcon sx={{ fontSize: 24 }} className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        }
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          { action === 'reviewed' &&
          <DialogTitle id="form-dialog-title">Marquer activité comme validé</DialogTitle>
          }
          { action === 'delete' &&
          <DialogTitle id="form-dialog-title">Supprimer activité</DialogTitle>
          }
          { action === 'restore' &&
          <DialogTitle id="form-dialog-title">Restaurer activité</DialogTitle>
          }
          { action === 'harddelete' &&
          <DialogTitle id="form-dialog-title">Supprimer activité définitivement</DialogTitle>
          }
          <DialogContent>
            { action === 'reviewed' &&
            <DialogContentText>Voulez-vous marquer cette activité comme validé ? L'activité sera déplacée vers la liste de toutes les activités. Vous pouvez toujours continuer à le modifier.</DialogContentText>
            }
            { action === 'delete' &&
            <DialogContentText>Êtes-vous sûr ?</DialogContentText>
            }
            { action === 'restore' &&
            <DialogContentText>Voulez-vous restaurer cette activité ?</DialogContentText>
            }
            { action === 'harddelete' &&
            <DialogContentText>Êtes-vous sûr ?</DialogContentText>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" size="small">
              Annuler
            </Button>
            <Button onClick={handleConfirm} color="primary" size="small">
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(ConfirmActionDialog)