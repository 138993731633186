import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import {
  addIndicatorValue,
  deleteIndicatorValue,
  getIndicators, updateIndicatorValue
} from "../../utils/api";
import {CircularProgress, Step, StepConnector, StepLabel, Stepper, stepConnectorClasses, Divider} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Tooltip from "@mui/material/Tooltip";
import {formatTime} from "../../utils/timeHelper";
import {formatActivityType} from "../../utils/activityTypeHelper";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import AddIndicatorValueDialog from "../dialogs/AddIndicatorValueDialog";
import CompleteIcon from "@mui/icons-material/Verified";

const styles = () => ({
  paper: {
    maxWidth: '80%',
    minWidth: '70%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const arrSum = arr => arr.reduce((a, b) => a + b.numericValue, 0);
const arrSumSecondary = arr => arr.filter(v => v.secondaryNumericValue !== undefined).reduce((a, b) => a + b.secondaryNumericValue, 0);
const arrSumTertiary = arr => arr.filter(v => v.tertiaryNumericValue !== undefined).reduce((a, b) => a + b.tertiaryNumericValue, 0);

const getActiveMilestone = arr => Math.max(...arr.filter(v => v.majorMilestone !== undefined).map(v => v.majorMilestone));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <div>{children}</div>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 12,
  },
}))(Tooltip);

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgressWithLabel);

function LinearProgressWithLabel(props) {
  return (
      <Box display="flex" alignItems="center">
        <Box width="90%" mr={1}>
          <LinearProgress sx={{height: 24}} variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {props.current} / {props.target}
          </Typography>
        </Box>
      </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#B72B20',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#B72B20',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 4,
    borderRadius: 5,
  },
}));

function HorizontalLinearAlternativeLabelStepper(props) {
  return (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={props.activeMilestone} alternativeLabel connector={<CustomConnector />}>
          {props.milestones.map((m) => (
              <Step key={m.priority}>
                <StepLabel>{m.description}</StepLabel>
              </Step>
          ))}
        </Stepper>
      </Box>
  );
}

function OutputIndicatorPaper(props) {
  const { classes, indicator, updateHandler, confirmationHandler, removeValueHandler, removeHandler, indicatorType, updateValueHandler } = props;

  const [open, setOpen] = useState(false);

  const collapseComponent = (props) => (
      <td colSpan={8}>
        <div className={props.className}>
          {props.children}
        </div>
      </td>
  )

  return <Paper className={classes.paper}>
    <AppBar className={classes.toolbar}
            position="static"
            color="default"
            elevation={0}>
      <Toolbar>
        <Typography variant="h2" gutterBottom component="div">
          {indicator.name}
        </Typography>
        <div style={{position: 'absolute', right: '1rem', top: '0.2rem', display: 'flex', flexDirection: 'row'}}>
        </div>
      </Toolbar>
    </AppBar>
    <div className={classes.contentWrapper}>
      <Typography color="textSecondary">
        {indicator.description}
      </Typography>
    </div>
    { indicator.targetValueNumeric === 1 && arrSum(indicator.indicatorValues) === 1 &&
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '16px 16px',
        color: 'green'
      }}>
        <span>Indicateur atteint</span>
        <CompleteIcon sx={{marginLeft: '0.5rem'}} />
      </div>
    }
    { indicator.targetValueNumeric === 1 && arrSum(indicator.indicatorValues) !== 1 &&
        <>
          <HorizontalLinearAlternativeLabelStepper milestones={indicator.milestones} activeMilestone={getActiveMilestone(indicator.indicatorValues)}/>
          <Divider style={{marginTop: '3rem'}} />
        </>
    }
    { indicator.targetValueNumeric !== 1 &&
      <div className={classes.contentWrapper}>
        <Typography color="textSecondary">
          {indicator.targetValueDescription}
        </Typography>
        <StyledLinearProgress
            value={Math.round((arrSum(indicator.indicatorValues))
                / (indicator.targetValueNumeric - indicator.startValueNumeric)
                * 100)}
            current={(indicator.startValueNumeric + arrSum(indicator.indicatorValues)).toLocaleString()}
            target={(indicator.targetValueNumeric).toLocaleString()}/>
      </div>
    }
    { indicator.targetValueNumeric !== 1 && indicator.secondaryTargetValueNumeric > 0 && indicator.secondaryStartValueNumeric >= 0 &&
      <div className={classes.contentWrapper}>
      <Typography color="textSecondary">
        {indicator.secondaryTargetValueDescription}
      </Typography>
      <StyledLinearProgress
      value={Math.round((arrSumSecondary(indicator.indicatorValues)) / (indicator.secondaryTargetValueNumeric - indicator.secondaryStartValueNumeric) * 100)}
      current={(indicator.secondaryStartValueNumeric + arrSumSecondary(indicator.indicatorValues)).toLocaleString()}
      target={(indicator.secondaryTargetValueNumeric).toLocaleString()} />
      </div>
    }
    { indicator.targetValueNumeric !== 1 && indicator.tertiaryTargetValueNumeric > 0 && indicator.tertiaryStartValueNumeric >= 0 &&
      <div className={classes.contentWrapper}>
      <Typography color="textSecondary">
        {indicator.tertiaryTargetValueDescription}
      </Typography>
      <StyledLinearProgress
      value={Math.round((arrSumTertiary(indicator.indicatorValues)) / (indicator.tertiaryTargetValueNumeric - indicator.tertiaryStartValueNumeric) * 100)}
      current={(indicator.tertiaryStartValueNumeric + arrSumTertiary(indicator.indicatorValues)).toLocaleString()}
      target={(indicator.tertiaryTargetValueNumeric).toLocaleString()} />
      </div>
    }
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell width={'20%'}>Description</TableCell>
            <TableCell width={'20%'}>Activité</TableCell>
            <TableCell width={'10%'}>Date</TableCell>
            <TableCell width={'10%'}>Inséré par</TableCell>
            <TableCell width={'20%'}>Commentaires</TableCell>
            {indicator.targetValueNumeric !== 1 &&
              <TableCell width={'5%'}>Nombre</TableCell>
            }
            <TableCell width={'10%'}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {indicator.indicatorValues.map((indicatorValue) => (
              <TableRow key={indicatorValue.id}>
                <TableCell width={'20%'}
                           style={{
                             whiteSpace: "normal",
                             wordBreak: "break-word"
                           }}>{indicatorValue.description}</TableCell>
                <TableCell width={'20%'}>
                  {indicatorValue.activity &&
                  <HtmlTooltip
                      title={
                        <>
                          <Typography color="inherit">{indicatorValue.activity.name}</Typography>
                          Type: {formatActivityType(indicatorValue.activity.activityType)} <br/>
                          Nom: {indicatorValue.activity.name} <br/>
                          Lieu: {indicatorValue.activity.location ? indicatorValue.activity.location.englishName : ''} <br/>
                          Date: {formatTime(indicatorValue.activity.date, 'dd.LL.yyyy')} <br/>
                        </>
                      }
                  >
                    <span><a href={'/activity/' + indicatorValue.activity.id}>{indicatorValue.activity.name}</a></span>
                  </HtmlTooltip>
                  }
                </TableCell>
                <TableCell width={'10%'}>{formatTime(indicatorValue.creationDate, 'dd.LL.yyyy')}</TableCell>
                <TableCell width={'10%'}>{indicatorValue.creator}</TableCell>
                <TableCell width={'20%'}
                           style={{
                             whiteSpace: "normal",
                             wordBreak: "break-word"
                           }}>{indicatorValue.comment}</TableCell>
                {indicator.targetValueNumeric !== 1 &&
                  <TableCell
                      width={'5%'}><strong>{indicatorValue.numericValue.toLocaleString()}</strong> {indicatorValue.secondaryNumericValue
                      ? `/ ${indicatorValue.secondaryNumericValue.toLocaleString()}`
                      : ''} {indicatorValue.tertiaryNumericValue
                      ? `/ ${indicatorValue.tertiaryNumericValue.toLocaleString()}`
                      : ''}</TableCell>
                }
                <TableCell width={'10%'}>
                  <div style={{display: 'flex'}} >
                    <AddIndicatorValueDialog action={'edit'} indicator={indicator} entity={indicatorValue} confirmationHandler={updateValueHandler} />
                    <ConfirmActionDialog action="delete" confirmationHandler={() => removeValueHandler(indicator.id, indicatorValue.id)}/>
                  </div>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <div className={classes.contentWrapper}>
      <AddIndicatorValueDialog indicator={indicator} confirmationHandler={confirmationHandler}/>
    </div>
  </Paper>
}

function TabbedOutputIndicators(props) {
  const { classes } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const removeIndicatorValue = async (indicatorId, indicatorValueId) => {
    await deleteIndicatorValue(indicatorId, indicatorValueId);
    await fetchData();
  };

  const confirmationHandler =
      async (indicatorId, description, activity, comment, numericValue, secondaryNumericValue, tertiaryNumericValue, majorMilestone) => {
        let body = {
          'description': description,
          'activity': activity,
          'comment': comment,
          'numericValue': +numericValue,
          'secondaryNumericValue': +secondaryNumericValue,
          'tertiaryNumericValue': +tertiaryNumericValue
        }

        if (majorMilestone) {
          body['majorMilestone'] = +majorMilestone
        }

        await addIndicatorValue(indicatorId, body);
        await fetchData();
      };

  const confirmIndicatorValueUpdate =
      async (indicatorValueId, indicatorId, description, activity, comment,
          numericValue, secondaryNumericValue, tertiaryNumericValue, majorMilestone) => {
        let body = {
          'description': description,
          'activity': activity,
          'comment': comment,
          'numericValue': +numericValue,
          'secondaryNumericValue': +secondaryNumericValue,
          'tertiaryNumericValue': +tertiaryNumericValue
        }

        if (majorMilestone) {
          body['majorMilestone'] = +majorMilestone
        }

        await updateIndicatorValueDataAndDispatch(indicatorId, indicatorValueId, body);
        await fetchData();
      };


  const updateIndicatorValueDataAndDispatch = async (indicatorId, indicatorValueId, fields) => {
    try {
      await updateIndicatorValue(
          indicatorId,
          indicatorValueId,
          fields
      );
    } catch (error) {
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [indicators, setIndicators] = useState('');

  const cancelRequest = useRef();

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getIndicators();
          const indicatorData = result.data;

          if (!cancelRequest.current) {
            if (indicatorData && Object.keys(indicatorData).length > 0) {
              setIndicators(indicatorData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
  };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <Typography variant="h6" color="primary" style={{marginTop: '10%'}}>
              Impossible de récupérer des indicateurs!
            </Typography>
          </div>
      );
    }

    if (isLoading || !indicators) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    return (
        <>
          <AppBar
              component="div"
              className={classes.appBar}
              color="primary"
              position="static"
              elevation={0}
          >
            <Tabs value={value} textColor={'#fff'} TabIndicatorProps={{style: {background:'white'}}} onChange={handleChange}>
              <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 1" {...a11yProps(0)}/>
              <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 2" {...a11yProps(1)}/>
              <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 3" {...a11yProps(2)}/>
              <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 4" {...a11yProps(3)}/>
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {indicators.map((indicator) => {
              if ('OUTPUT_1' === indicator.indicatorType) {
                return <OutputIndicatorPaper key={indicator.id} classes={classes} indicator={indicator} confirmationHandler={confirmationHandler} removeValueHandler={removeIndicatorValue} updateValueHandler={confirmIndicatorValueUpdate}/>
              } else return null;
            })}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {indicators.map((indicator) => {
              if ('OUTPUT_2' === indicator.indicatorType) {
                return <OutputIndicatorPaper key={indicator.id} classes={classes} indicator={indicator} confirmationHandler={confirmationHandler} removeValueHandler={removeIndicatorValue} updateValueHandler={confirmIndicatorValueUpdate}/>
              } else return null;
            })}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {indicators.map((indicator) => {
              if ('OUTPUT_3' === indicator.indicatorType) {
                return <OutputIndicatorPaper key={indicator.id} classes={classes} indicator={indicator} confirmationHandler={confirmationHandler} removeValueHandler={removeIndicatorValue} updateValueHandler={confirmIndicatorValueUpdate}/>
              } else return null;
            })}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {indicators.map((indicator) => {
              if ('OUTPUT_4' === indicator.indicatorType) {
                return <OutputIndicatorPaper key={indicator.id} classes={classes} indicator={indicator} confirmationHandler={confirmationHandler} removeValueHandler={removeIndicatorValue} updateValueHandler={confirmIndicatorValueUpdate}/>
              } else return null;
            })}
          </TabPanel>
        </>
    );
  };

  return renderData();
}

TabbedOutputIndicators.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TabbedOutputIndicators);
