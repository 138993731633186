import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import StatisticsIcon from '@mui/icons-material/Assessment';
import {UserContext} from "./auth/UserProvider";
import PeopleIcon from '@mui/icons-material/People';
import LocationIcon from '@mui/icons-material/AddLocationAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Divider,
  Drawer,
  List,
  ListItem, ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import {withStyles} from "@mui/styles";

const categories = [
  {
    id: 'Général',
    children: [
      { id: 'Tableau de bord', icon: <StatisticsIcon sx={{ fontSize: 24, color: 'black' }} />,
        href: '/dashboard' },
    ],
  },

  {
    id: 'Activités',
    children: [
      { id: 'Nouvelles activités', icon: <EventNoteIcon sx={{ fontSize: 24, color: 'black' }} />,
        href: '/activities/new', showToAppUser: true },
      { id: 'Activités validées', icon: <EventNoteIcon sx={{ fontSize: 24, color: 'black' }} />,
        href: '/activities' },
      // { id: 'Activités sans sondage', icon: <EventNoteIcon sx={{ fontSize: 24, color: 'black' }} />,
      //   href: '/activities/unsurveyed' },
      { id: 'Corbeille', icon: <DeleteIcon sx={{ fontSize: 24, color: 'black' }} />,
        href: '/activities/deleted' },
    ],
    showToAppUser: true,
  },
  {
    id: 'Indicateurs',
    children: [
      { id: 'Programme', icon: <DescriptionIcon sx={{ fontSize: 24, color: 'black' }} />,
        href: '/indicators/programme' },
      { id: 'Module', icon: <DescriptionIcon sx={{ fontSize: 24, color: 'black' }} />, href: '/indicators/module' },
      { id: 'Output', icon: <DescriptionIcon sx={{ fontSize: 24, color: 'black' }} />, href: '/indicators/output' },
    ],
  },
  {
    id: 'Analyse des données',
    children: [
      { id: 'Programme', icon: <BarChartIcon sx={{ fontSize: 24, color: 'black' }} />, href: '/program/analysis' },
      { id: 'Module', icon: <BarChartIcon sx={{ fontSize: 24, color: 'black' }} />, href: '/module/analysis' },
      { id: 'Output', icon: <BarChartIcon sx={{ fontSize: 24, color: 'black' }} />, href: '/output/analysis' },
    ],
  },
  {
    id: 'Réglages',
    children: [
      { id: 'Utilisateurs', icon: <PeopleIcon sx={{ fontSize: 24, color: 'black' }} />, href: '/users'},
      { id: 'Lieux', icon: <LocationIcon sx={{ fontSize: 24, color: 'black' }} />, href: '/locations'},
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.black,
  },
  item: {
    color: '#000000',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.01)',
    },
  },
  itemCategory: {
    backgroundColor: '#ffffff',
    boxShadow: '0 -1px 0 #B72A20 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 12,
    color: theme.palette.common.black,
  },
  itemActiveItem: {
    color: '#B3291C',
  },
  itemPrimary: {
    fontSize: 32,
  },
  itemIcon: {
    minWidth: 'auto',
  },
  divider: {
    color: theme.palette.common.black,
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, ...other } = props;

  const authState = useContext(UserContext);

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <table>
            <tbody>
              <tr>
                <td>
                  <img src={process.env.PUBLIC_URL + '/giz_logo.png'} alt="logo" width="100px"/>
                </td>
                <td style={{fontSize: 16}}><strong>Maadini's App</strong> - DISM2</td>
              </tr>
            </tbody>
          </table>

        </ListItem>

        {authState.user && categories.filter(cat => {
          return !(authState.role === 'ROLE_APP' && !cat.showToAppUser);
        }).map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                primaryTypographyProps={{ sx: {fontSize: 16, fontWeight: 'bold'}}}
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.filter(child => {
              return !(authState.role === 'ROLE_APP' && !child.showToAppUser);
            }).map(({ id: childId, icon, active, href }) => (
              <ListItemButton
                key={childId}
                component="a"
                sx={{
                  height: 42,
                }}
                href={href}
                className={clsx(classes.item, active && classes.itemActiveItem)}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ sx: {display: 'inline', fontWeight: 'bold'}}}
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItemButton>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);