import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import useFetchData from "../../utils/useFetchData";
import {getOutputAnalysis} from "../../utils/api";
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Chart from "react-apexcharts";

const styles = () => ({
  paper: {
    maxWidth: '95%',
    minWidth: '70%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  totalProgress: {
    marginTop: 'auto',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <div>{children}</div>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const arrSum = arr => arr.reduce((a, b) => a + b.numericValue, 0);

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgressWithLabel);

function LinearProgressWithLabel(props) {
  return (
      <Box display="flex" alignItems="center">
        <Box width="90%" mr={1}>
          <LinearProgress sx={{height: 24}} color={'primary'} variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {props.current.toLocaleString()} / {props.target.toLocaleString()} ({Math.round(((props.current / props.target) + Number.EPSILON)  * 100)}%)
          </Typography>
        </Box>
      </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function OutputAnalysisTab(props) {
  const { classes, outputId } = props;
  const { data, isLoading, isError } = useFetchData(getOutputAnalysis, outputId);


  const renderData = () => {
    if (isError) {
      return (
          <div>
            <Typography style={{marginLeft: '40%', marginTop: '20%'}} color="primary">
              Error
            </Typography>
          </div>
      );
    }

    if (isLoading || !data) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    const pieChartDataSeries = [];
    const pieChartDataOptions = {
      labels: [],
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
      },
      dataLabels: {
        style: {
          fontSize: '24px',
        },
      },
      colors: [
        '#003f5c',
        '#2f4b7c',
        '#665191',
        '#a05195',
        '#d45087',
        '#f95d6a',
        '#ff7c43',
        '#ffa600'
      ],
    };

    const pieChartTwoDataSeries = [];

    if (data.analysisSummary) {
      pieChartDataSeries.push(data.analysisSummary[0].numberSeminars);
      pieChartTwoDataSeries.push(data.analysisSummary[1].numberSeminars);
      pieChartDataOptions.labels.push('Séminaires');
      pieChartDataSeries.push(data.analysisSummary[0].numberWorkshops);
      pieChartTwoDataSeries.push(data.analysisSummary[1].numberWorkshops);
      pieChartDataOptions.labels.push('Ateliers');
      pieChartDataSeries.push(data.analysisSummary[0].numberPublications);
      pieChartTwoDataSeries.push(data.analysisSummary[1].numberPublications);
      pieChartDataOptions.labels.push('Publications');
      pieChartDataSeries.push(data.analysisSummary[0].numberCampaigns);
      pieChartTwoDataSeries.push(data.analysisSummary[1].numberCampaigns);
      pieChartDataOptions.labels.push('Campagnes');
    }

    return (
        <>
          <Grid className={classes.contentWrapper} container spacing={3}>
            <Grid item xs={8}>
              <Paper style={{height: '100%', minHeight: '400px'}} className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Types d'activités
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Grid className={classes.contentWrapper} container spacing={3}>
                  <Grid item xs={4}>
                    <Typography variant="h5" component="div" style={{ marginLeft: '1rem', marginTop: '1rem'}}>
                      { data.indicators[1].name }
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisSummary[1].numberSeminars} séminaire(s)`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisSummary[1].numberWorkshops} atelier(s)`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisSummary[1].numberPublications} publication(s)`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisSummary[1].numberCampaigns} campagne(s) médiatique(s)`}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={8}>
                    <Chart options={pieChartDataOptions} series={pieChartDataSeries} type="pie" />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" component="div" style={{ marginLeft: '1rem'}}>
                      { data.indicators[0].name }
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisSummary[0].numberSeminars} séminaire(s)`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisSummary[0].numberWorkshops} atelier(s)`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisSummary[0].numberPublications} publication(s)`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisSummary[0].numberCampaigns} campagne(s) médiatique(s)`}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={8}>
                    <Chart options={pieChartDataOptions} series={pieChartTwoDataSeries} type="pie" />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{height: '100%', minHeight: '400px'}} className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Statistiques
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Typography variant="h5" component="div" style={{ marginLeft: '1rem', marginTop: '1rem'}}>
                  { data.indicators[1].name }
                </Typography>
                <List>
                  { data.analysisStatistics[1].totalParticipants > 0 &&
                  <ListItem>
                    <ListItemText
                        primary={`${data.analysisStatistics[1].totalParticipants} participants`}
                    />
                  </ListItem>
                  }
                  { data.analysisStatistics[1].femaleParticipants > 0 &&
                    <ListItem>
                      <ListItemText
                          secondary={`${data.analysisStatistics[1].femaleParticipants} participants femmes`}
                      />
                    </ListItem>
                  }
                  { data.analysisStatistics[1].maleParticipants > 0 &&
                  <ListItem>
                    <ListItemText
                        secondary={`${data.analysisStatistics[1].maleParticipants} participants hommes`}
                    />
                  </ListItem>
                  }
                  { data.analysisStatistics[1].youngParticipants > 0 &&
                  <ListItem>
                    <ListItemText
                        secondary={`${data.analysisStatistics[1].youngParticipants} participants jeunes`}
                    />
                  </ListItem>
                  }
                </List>
                <Typography variant="h5" component="div" style={{ marginLeft: '1rem', marginTop: '1rem'}}>
                  { data.indicators[0].name }
                </Typography>
                <List>
                  { data.analysisStatistics[0].totalParticipants > 0 &&
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisStatistics[0].totalParticipants} participants`}
                        />
                      </ListItem>
                  }
                  { data.analysisStatistics[0].femaleParticipants > 0 &&
                      <ListItem>
                        <ListItemText
                            secondary={`${data.analysisStatistics[0].femaleParticipants} participants femmes`}
                        />
                      </ListItem>
                  }
                  { data.analysisStatistics[0].maleParticipants > 0 &&
                      <ListItem>
                        <ListItemText
                            secondary={`${data.analysisStatistics[0].maleParticipants} participants hommes`}
                        />
                      </ListItem>
                  }
                  { data.analysisStatistics[0].youngParticipants > 0 &&
                      <ListItem>
                        <ListItemText
                            secondary={`${data.analysisStatistics[0].youngParticipants} participants jeunes`}
                        />
                      </ListItem>
                  }
                </List>
                <Typography variant="h5" component="div" style={{ marginLeft: '1rem', marginTop: '1rem'}}>
                  Total
                </Typography>
                <List>
                  { data.analysisOverallStatistics.totalParticipants > 0 &&
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisOverallStatistics.totalParticipants} participants`}
                        />
                      </ListItem>
                  }
                  { data.analysisOverallStatistics.femaleParticipants > 0 &&
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisOverallStatistics.femaleParticipants} participants femmes`}
                        />
                      </ListItem>
                  }
                  { data.analysisOverallStatistics.maleParticipants > 0 &&
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisOverallStatistics.maleParticipants} participants hommes`}
                        />
                      </ListItem>
                  }
                  { data.analysisOverallStatistics.youngParticipants > 0 &&
                      <ListItem>
                        <ListItemText
                            primary={`${data.analysisOverallStatistics.youngParticipants} participants jeunes`}
                        />
                      </ListItem>
                  }
                </List>
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper style={{height: '100%', minHeight: '200px'}} className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      État d'avancement
                    </Typography>
                  </Toolbar>
                </AppBar>
                { data.indicators.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((indicator) => (
                    <div key={indicator.id} className={classes.contentWrapper}>
                      <Typography variant="overline" align={'center'} color="textPrimary">
                        {indicator.name}
                      </Typography>
                      <div style={{ fontSize: 14, marginBottom: '1rem' }}>
                        {indicator.description}
                      </div>
                      <StyledLinearProgress
                          value={Math.round(arrSum(indicator.indicatorValues) / indicator.targetValueNumeric * 100)}
                          current={indicator.startValueNumeric + arrSum(indicator.indicatorValues)}
                          target={indicator.targetValueNumeric}/>
                    </div>
                ))}
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{height: '100%', minHeight: '200px'}} className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Progrès total
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Typography variant="h2" style={{marginTop: '1rem'}} align={'center'} color="textPrimary" className={classes.totalProgress}>
                  {data.totalPercentage}%
                </Typography>
              </Paper>
            </Grid>
          </Grid>

        </>
     );
  };

  return renderData();
}

function OutputAnalysis(props) {
  const { classes } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
      <>
        <AppBar
            component="div"
            className={classes.appBar}
            color="primary"
            position="static"
            elevation={0}
        >
          <Tabs value={value} textColor={'#fff'} TabIndicatorProps={{style: {background:'white'}}} onChange={handleChange}>
            <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 1" {...a11yProps(0)}/>
            <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 2" {...a11yProps(1)}/>
            <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 3" {...a11yProps(2)}/>
            <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 4" {...a11yProps(3)}/>
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <OutputAnalysisTab key={1} outputId={1} classes={classes}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OutputAnalysisTab key={2} outputId={2} classes={classes}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <OutputAnalysisTab key={3} outputId={3} classes={classes}/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <OutputAnalysisTab key={4} outputId={4} classes={classes}/>
        </TabPanel>
      </>
    );
}

OutputAnalysis.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OutputAnalysis);
